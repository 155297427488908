import { BACKEND_DOMAIN } from '../../constants/Branding';

export const DevUrlsMap = {
  REACT_APP_REST_API_URL: `https://stage-api.maruti.dev/api/v1`,
  REACT_APP_REST_API_V2_URL: `https://stage-api.maruti.dev`,
  REACT_APP_REST_REPORTING_API_URL: `https://stage-reporting.maruti.dev`,
  REACT_APP_WEBSOCKET_URL_PUSH_NOTIFICATIONS: `https://stage-reporting.maruti.dev/push-notifications`,
  BINARY_WEBSOCKET_URL: `https://feed.mysportsfeed.io/hypex-ws`,
  REACT_APP_REST_AGPAY_URL: `https://stage-agpay.maruti.dev`,
  REACT_APP_SPORT_FEED_URL: `https://stage-api.mysportsfeed.io/api/v1`,
};

export const ProdUrls = {
  REACT_APP_REST_API_URL: `https://api.${BACKEND_DOMAIN}/api/v1`,
  REACT_APP_REST_API_V2_URL: `https://api.${BACKEND_DOMAIN}`,
  REACT_APP_REST_REPORTING_API_URL: `https://reporting.${BACKEND_DOMAIN}`,
  REACT_APP_WEBSOCKET_URL_PUSH_NOTIFICATIONS: `https://reporting.${BACKEND_DOMAIN}/push-notifications`,
  BINARY_WEBSOCKET_URL: `https://feed.mysportsfeed.io/hypex-ws`,
  REACT_APP_REST_AGPAY_URL: `https://agpay.${BACKEND_DOMAIN}`,
  REACT_APP_SPORT_FEED_URL: `https://api.mysportsfeed.io/api/v1`,
};

export const EnvUrlsMap = {
  development: DevUrlsMap,
  stage: DevUrlsMap,
  production: ProdUrls,
};

export function getBaseUrl(env: string, url: string) {
  return EnvUrlsMap[env][url];
}
