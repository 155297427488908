import React from 'react';
import LoginForm from '../../components/LoginForm/LoginForm';
import './LoginPage.scss';
import webtitleImg from '../../assets/images/brand/logintitle.png';
import login_img from '../../assets/images/brand/login_img.png';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router';
const modalCloseHandler = () => {};
type StoreProps = {
  closeHandler: () => void;
  backHandler: () => void;
};
const LoginPage: React.FC<StoreProps> = (props) => {
  const { closeHandler } = props;
  let history = useHistory();
  const onRedirectToHome = () => {
    closeHandler();
    history.push('/home');
  };
  return (
    <div className="login-ctn">
      <div className="web-view new_login_ctn">
        <div className="login_image">
          <img src={login_img} alt="mazaa" className="img_algin" />
        </div>
        <div className="login_right_ctn">
          <div className="title-row">
            <img src={webtitleImg} alt="mazaa" className="logo" />
          </div>

          <div className="login-card">
            <LoginForm modalCloseHandler={closeHandler} />
          </div>
        </div>
      </div>
      <div className="mob-view new_login_ctn_mob">
        <div className="login_image moblie_image">
          <img src={login_img} alt="mazaa" className="img_algin" />
        </div>
        <div className="login_right_ctn mob_card">
          <div className="title-row">
            <img src={webtitleImg} alt="mazaa" className="logo" />
          </div>

          <div className="login-card">
            <LoginForm modalCloseHandler={onRedirectToHome} />
          </div>
        </div>
      </div>
      <div className="login_btn_align web-view">
        <Button
          className="home_btn"
          color="primary"
          onClick={onRedirectToHome}
          variant="contained"
        >
          BACK TO HOME
        </Button>
      </div>
    </div>
  );
};

export default LoginPage;
