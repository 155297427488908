export const ShowWelcomePage: { [key: string]: boolean } = {
  //faircric
  'faircric.co': true,
  'faircric.in': true,
  'faircric.live': true,
  'faircric.world': true,
  'faircric.co.in': true,
  'faircric.online': true,
  //mazaa365
  'mazaa365.in': true,
  'mazaa365.co': true,
  'mazaa365.live': true,
  'mazaa365.world': true,
  'mazaa365.co.in': true,
  'mazaa365.online': true,
  //betex247
  'betex247.in': true,
  'betex247.co': true,
  'betex247.live': true,
  'betex247.world': true,
  'betex247.co.in': true,
  'betex247.online': true,
};

export const enableDemoLoginWls: { [key: string]: boolean } = {
  mazaa: true,
  mirchiexch9: true,
  sultan444: true,
  shreejiexch9: true,
  sapphire333: true,
  sikander247: true,
  maruti: true,
  sprinters: true,
  sprint365: true,
};

export const WHDetails: {
  [key: string]: {
    whatsapp: string;
    bank: string;
    accType: string;
    accName: string;
    accNum: string;
    ifsc: string;
    gpayNum: string;
    gpayUpi: string;
    phonePayNum: string;
    phonePayUpi: string;
    paytmNum: string;
  };
} = {
  dingo777: {
    whatsapp: '+919328893488',
    bank: 'ICIC Bank',
    accType: 'CURRENT ACCOUNT',
    accName: 'HARIOM JEWELLERS',
    accNum: '030805501063',
    ifsc: 'ICIC0000308',
    gpayNum: '+9195860 03386',
    gpayUpi: 'jalaramjnd1@okaxis',
    phonePayNum: '+919586003386',
    phonePayUpi: 'ronakatm@ybl',
    paytmNum: null,
  },
  wazirexch: {
    whatsapp: '+91**********',
    bank: '***** Bank',
    accType: 'CURRENT ACCOUNT',
    accName: '**** *****',
    accNum: '********',
    ifsc: '*******',
    gpayNum: '+91******',
    gpayUpi: '******@****',
    phonePayNum: '+91********',
    phonePayUpi: '******@***',
    paytmNum: null,
  },
};

export const ShowSkinIcon: { [key: string]: boolean } = {
  quickexch: true,
  sportsplay7: true,
  payquickexch: true,
  maruti: true,
};
